<template>
  <div>
    <!-- 頭部組件 -->
    <nav-header :type="1"></nav-header>

    <div class="app-container headphoneDetection">
      <div class="box">
        <!-- 步驟 7-8級 -->
        <div class="box-setp" v-if="['1', '2', '3', '4', '5', '6', '7', '8'].includes(entryLevel)">
          <div class="box-setp-div active">
            <img class="box-setp-div-img" src="@/assets/image/hardware/step1.png" alt="第一步">
            <p class="box-setp-div-p">耳機測試</p>
          </div>
          <div class="box-setp-arrow">
            <i class="box-setp-arrow-left"></i>
            <img class="box-setp-arrow-img" src="@/assets/image/hardware/arrows.png" alt="">
            <i class="box-setp-arrow-right"></i>
          </div>
          <div class="box-setp-div">
            <img class="box-setp-div-img" src="@/assets/image/hardware/steps2.png" alt="第二步">
            <p class="box-setp-div-p">麥克風測試</p>
          </div>
          <div class="box-setp-arrow">
            <i class="box-setp-arrow-left"></i>
            <img class="box-setp-arrow-img" src="@/assets/image/hardware/arrows.png" alt="">
            <i class="box-setp-arrow-right"></i>
          </div>
          <div class="box-setp-div">
            <img class="box-setp-div-img" src="@/assets/image/hardware/steps3.png" alt="第三步">
            <p class="box-setp-div-p">鏡頭測試</p>
          </div>
          <div class="box-setp-arrow">
            <i class="box-setp-arrow-left"></i>
            <img class="box-setp-arrow-img" src="@/assets/image/hardware/arrows.png" alt="">
            <i class="box-setp-arrow-right"></i>
          </div>
          <div class="box-setp-div">
            <img class="box-setp-div-img" src="@/assets/image/hardware/steps4.png" alt="第四步">
            <p class="box-setp-div-p">網速測試</p>
          </div>
          <div class="box-setp-arrow">
            <i class="box-setp-arrow-left"></i>
            <img class="box-setp-arrow-img" src="@/assets/image/hardware/arrows.png" alt="">
            <i class="box-setp-arrow-right"></i>
          </div>
          <div class="box-setp-div">
            <img class="box-setp-div-img" src="@/assets/image/hardware/steps5.png" alt="第五步">
            <p class="box-setp-div-p">螢幕分享</p>
          </div>
        </div>
        <!-- 步驟 1-6級 -->
        <!-- <div class="box-setp" v-if="['1', '2', '3', '4', '5', '6'].includes(entryLevel)">
          <div class="box-setp-div active">
            <img class="box-setp-div-img" src="@/assets/image/hardware/step1.png" alt="第一步">
            <p class="box-setp-div-p">耳機測試</p>
          </div>
          <div class="box-setp-arrow">
            <i class="box-setp-arrow-left"></i>
            <img class="box-setp-arrow-img" src="@/assets/image/hardware/arrows.png" alt="">
            <i class="box-setp-arrow-right"></i>
          </div>
          <div class="box-setp-div">
            <img class="box-setp-div-img" src="@/assets/image/hardware/steps2.png" alt="第二步">
            <p class="box-setp-div-p">鏡頭測試</p>
          </div>
          <div class="box-setp-arrow">
            <i class="box-setp-arrow-left"></i>
            <img class="box-setp-arrow-img" src="@/assets/image/hardware/arrows.png" alt="">
            <i class="box-setp-arrow-right"></i>
          </div>
          <div class="box-setp-div">
            <img class="box-setp-div-img" src="@/assets/image/hardware/steps3.png" alt="第三步">
            <p class="box-setp-div-p">網速測試</p>
          </div>
          <div class="box-setp-arrow">
            <i class="box-setp-arrow-left"></i>
            <img class="box-setp-arrow-img" src="@/assets/image/hardware/arrows.png" alt="">
            <i class="box-setp-arrow-right"></i>
          </div>
          <div class="box-setp-div">
            <img class="box-setp-div-img" src="@/assets/image/hardware/steps4.png" alt="第四步">
            <p class="box-setp-div-p">螢幕分享</p>
          </div>
        </div> -->

        <!-- 內容 -->
        <div class="box-content">
          <div class="box-content-left">
            <img src="@/assets/image/hardware/detection1.png" alt="耳機測試">
          </div>
          <div class="box-content-right">
            <h1 class="box-content-right-h1">
              耳機測試<span>（聽力/口說測驗）</span>
            </h1>
            <h2 class="box-content-right-h2">
              請按下播放鍵聆聽測試內容，<br />
              並「將您的電腦調整至最適當的音量」。
            </h2>
            <div class="box-content-right-div">
              <div class="div-left">
                <img @click="playAudio" src="@/assets/image/icon/icon-plays.png" alt="">
              </div>
              <div class="div-right">
                <div class="div-right-time">
                  <span>{{currentTime}}</span>
                  <span>{{duration}}</span>
                </div>
                <div class="div-right-progress">
                  <el-slider v-model="percentage" :show-tooltip="false" disabled></el-slider>
                  <!-- <div class="progress-round" :style="{ left: percentage + '%' }"></div>
                  <el-progress :percentage="percentage" :show-text="false"></el-progress> -->
                  <audio ref="singeBox"></audio>
                </div>
              </div>
            </div>
            <div class="box-content-right-button">
              <div class="button1" @click="openQuestion">有問題請點我</div>
              <div class="button2" @click="next">
                NEXT
                <img src="@/assets/image/icon/icon-next.png" alt="">
              </div>
            </div>
          </div>
        </div>

        <!-- 提醒 -->
        <div class="box-remind">
          <img src="@/assets/image/hardware/remind.png" alt="提醒">
          {{ examinationRoomType == 3 ? '提醒：若無法排除問題，請加入line@帳號:@688qwier，由客服為您服務。' : '提醒：若無法排除問題，請撥打電話04-3707-7396 或 加入line@帳號:@688qwier，由客服為您服務。' }}
        </div>
      </div>
    </div>
    
    <!-- 問題彈窗 -->
    <detection-popup v-model="popupShow" type="headphone"></detection-popup>

    <!-- 底部組件 -->
    <nav-footer></nav-footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import mp3 from "@/assets/audio/test.mp3";
import DetectionPopup from './components/DetectionPopup.vue'
export default {
  name: 'HeadphoneDetection',
  components: { DetectionPopup },
  data() {
    return {
      popupShow: false,
      aduibox: '',      // audio对象
      duration: '',     // 音乐总时长
      currentTime: '',  // 当前播放时长
      percentage: 0,    // 進度條
    }
  },
  watch: {
    popupShow(val) {
      if (this.aduibox) {
        if (val) {
          this.aduibox.pause()
        }
      }
    }
  },
  computed: {
    ...mapGetters(['entryLevel', 'examinationRoomType']),
  },
  mounted() {
    this.init()
  },
  methods: {
    // 有問題請點我
    openQuestion() {
      this.popupShow = true
    },
    // 下一步
    next() {
      if (['1', '2', '3', '4', '5', '6', '7', '8'].includes(this.entryLevel)) {
        this.$router.push({
          path: '/microphoneDetection'
        })
      } else {
        this.$router.push({
          path: '/shotDetection'
        })
      }
    },
    playAudio() {
      this.aduibox.play()
    },
    init() {
      this.aduibox = this.$refs.singeBox
      this.aduibox.src = mp3
      const _that = this
      // 当时长有变化时触发，由"NaN"变为实际时长也算
      this.aduibox.ondurationchange = function() {
        console.log('时长发生了变化')
        _that.updateTime()
      }
      // 当前数据可用是触发
      this.aduibox.oncanplay = function() {
        console.log('已经可以播放了')
      }
      // 播放位置发送改变时触发。
      this.aduibox.ontimeupdate = function() {
        console.log('播放位置发送了变动')
        _that.updateTime()
      }
      // 音频播放完毕
      this.aduibox.onended = function() {
        console.log('播放完毕，谢谢收听')
        // this.currentTime = 0.00
        // this.percentage = 0
      }
      // 音频播放完毕
      this.aduibox.onerror = function() {
        console.log('加载出错！')
      }
    },
    updateTime() { // 更新时间
      const total = this.formatTime(this.aduibox.duration)
      const current = this.formatTime(this.aduibox.currentTime)
      this.duration = `${total.min}:${total.sec}`
      this.currentTime = `${current.min}:${current.sec}`
      this.percentage = Number(this.aduibox.currentTime / this.aduibox.duration * 100)
    },
    formatTime(time) { // 格式化毫秒，返回String型分秒对象
      // 有可能没获取到，为NaN
      if (!time) return { min: '0', sec: '00' }
      return {
        min: Math.floor(time / 60).toString(),
        sec: Math.floor(time % 60).toString().padStart(2, '0')
      }
    },
  }
}
</script>

<style lang="less" scoped>
.headphoneDetection {
  .box {
    margin: 20px auto;
    width: 1320px;
    .box-setp {
      display: flex;
      justify-content: center;
      .box-setp-div {
        text-align: center;
        .box-setp-div-img {
          margin-bottom: 11px;
          width: 60px;
          height: 60px;
        }
        .box-setp-div-p {
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          text-align: center;
          letter-spacing: 0.01em;
          color: #989898;
        }
      }
      .box-setp-arrow {
        margin: 24px 0 0;
        padding: 0 60px;
        position: relative;
        width: 6px;
        height: 8px;
        .box-setp-arrow-img {
          width: 100%;
          height: 100%;
        }
        .box-setp-arrow-left {
          position: absolute;
          top: 6px;
          left: 0;
          margin: 0 4px;
          width: 50px;
          height: 0px;
          border: 1px dashed #D3D3D3;
        }
        .box-setp-arrow-right {
          position: absolute;
          top: 6px;
          right: 0;
          margin: 0 4px;
          width: 50px;
          height: 0px;
          border: 1px dashed #D3D3D3;
        }
      }
      .active {
        .box-setp-div-p {
          color: #0064D9;
        }
        .box-setp-arrow-left {
          border: 1px dashed #0665D8;
        }
        .box-setp-arrow-right {
          border: 1px dashed #0665D8;
        }
      }
    }

    .box-content {
      margin-top: 20px;
      padding: 50px 120px;
      width: calc(100% - 240px);
      height: 500px;
      min-height: calc(100vh - 444px);
      background: #FFFFFF;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      display: flex;
      align-items: center;
      .box-content-left {
        margin-right: 120px;
        width: 438px;
        height: 320px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .box-content-right {
        width: calc(100% - 560px);
        height: 100%;
        .box-content-right-h1 {
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 700;
          font-size: 30px;
          line-height: 40px;
          color: #333333;
          span {
            font-size: 24px;
          }
        }
        .box-content-right-h2 {
          margin: 20px 0 30px;
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 32px;
          color: #333333;
        }
        .box-content-right-div {
          padding: 16px 20px;
          width: 500px;
          background: #F5F7FD;
          border-radius: 8px;
          display: flex;
          align-items: center;
          .div-left {
            cursor: pointer;
            margin-right: 16px;
            width: 54px;
            height: 54px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .div-right {
            width: calc(100% - 70px);
            .div-right-time {
              margin-bottom: 10px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-family: "Microsoft JhengHei","微軟正黑體";
              font-style: normal;
              font-weight: 400;
              font-size: 20px;
              line-height: 27px;
              color: #4F4F4F;
            }
            .div-right-progress {
              width: 100%;
              height: 12px;
              position: relative;
              .progress-round {
                position: absolute;
                top: -4px;
                width: 20px;
                height: 20px;
                z-index: 1;
                transform: translateX(-50%);
                border-radius: 50%;
                background: #4277FF;
                box-shadow: 0px 4px 4px rgba(8, 76, 155, 0.25), inset 0px 0px 12px rgba(147, 133, 235, 0.4);
              }
              /deep/ .el-progress-bar__outer {
                height: 12px !important;
                background-color: #D3D3D3;
              }
              /deep/ .el-progress-bar__inner {
                background-color: unset;
                background-image: linear-gradient(90deg, #266DD7 3.31%, #84B5FF 100%);
              }
              /deep/ .el-slider__runway {
                margin: 0;
                width: 100%;
                height: 12px;
                background-color: #D3D3D3;
                border-radius: 12px;
                .el-slider__bar {
                  width: 100%;
                  height: 100%;
                  border-radius: 12px;
                  background-image: linear-gradient(90deg, #266DD7 3.31%, #84B5FF 100%);
                }
              }
              /deep/ .el-slider__button-wrapper {
                margin-top: 11px;
                width: 16px;
                height: 16px;
                z-index: 2;
                // border-radius: 50%;
                // background: #4277FF;
                // box-shadow: 0px 4px 4px rgba(8, 76, 155, 0.25), inset 0px 0px 12px rgba(147, 133, 235, 0.4);
                .el-slider__button {
                  width: 16px;
                  height: 16px;
                  border: none;
                  background: #4277FF;
                  box-shadow: 0px 4px 4px rgba(8, 76, 155, 0.25), inset 0px 0px 12px rgba(147, 133, 235, 0.4);
                }
              }
            }
          }
        }
        .box-content-right-button {
          user-select: none;
          margin-top: 80px;
          display: flex;
          align-items: center;
          .button1 {
            cursor: pointer;
            margin-right: 22px;
            width: 200px;
            height: 58px;
            border: 1px solid #0C4FA2;
            border-radius: 8px;
            background: #FFFFFF;
            font-family: "Microsoft JhengHei","微軟正黑體";
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 24px;
            letter-spacing: 0.01em;
            color: #0C4FA2;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .button2 {
            cursor: pointer;
            width: 200px;
            height: 58px;
            border: 1px solid #0C4FA2;
            border-radius: 8px;
            background: #0C4FA2;
            box-shadow: 0px 4px 8px rgba(0, 73, 207, 0.3);
            border-radius: 8px;
            font-family: "Microsoft JhengHei","微軟正黑體";
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 24px;
            letter-spacing: 0.01em;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              margin-left: 6px;
              width: 30px;
              height: 30px;
            }
          }
        }
      }
    }

    .box-remind {
      margin-top: 20px;
      width: 100%;
      height: 48px;
      background: #FDF3EC;
      border-radius: 8px;
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 27px;
      color: #DA6B29;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 10px;
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>
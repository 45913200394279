var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('nav-header',{attrs:{"type":1}}),_c('div',{staticClass:"app-container headphoneDetection"},[_c('div',{staticClass:"box"},[(['1', '2', '3', '4', '5', '6', '7', '8'].includes(_vm.entryLevel))?_c('div',{staticClass:"box-setp"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)]):_vm._e(),_c('div',{staticClass:"box-content"},[_vm._m(9),_c('div',{staticClass:"box-content-right"},[_vm._m(10),_vm._m(11),_c('div',{staticClass:"box-content-right-div"},[_c('div',{staticClass:"div-left"},[_c('img',{attrs:{"src":require("@/assets/image/icon/icon-plays.png"),"alt":""},on:{"click":_vm.playAudio}})]),_c('div',{staticClass:"div-right"},[_c('div',{staticClass:"div-right-time"},[_c('span',[_vm._v(_vm._s(_vm.currentTime))]),_c('span',[_vm._v(_vm._s(_vm.duration))])]),_c('div',{staticClass:"div-right-progress"},[_c('el-slider',{attrs:{"show-tooltip":false,"disabled":""},model:{value:(_vm.percentage),callback:function ($$v) {_vm.percentage=$$v},expression:"percentage"}}),_c('audio',{ref:"singeBox"})],1)])]),_c('div',{staticClass:"box-content-right-button"},[_c('div',{staticClass:"button1",on:{"click":_vm.openQuestion}},[_vm._v("有問題請點我")]),_c('div',{staticClass:"button2",on:{"click":_vm.next}},[_vm._v(" NEXT "),_c('img',{attrs:{"src":require("@/assets/image/icon/icon-next.png"),"alt":""}})])])])]),_c('div',{staticClass:"box-remind"},[_c('img',{attrs:{"src":require("@/assets/image/hardware/remind.png"),"alt":"提醒"}}),_vm._v(" "+_vm._s(_vm.examinationRoomType == 3 ? '提醒：若無法排除問題，請加入line@帳號:@688qwier，由客服為您服務。' : '提醒：若無法排除問題，請撥打電話04-3707-7396 或 加入line@帳號:@688qwier，由客服為您服務。')+" ")])])]),_c('detection-popup',{attrs:{"type":"headphone"},model:{value:(_vm.popupShow),callback:function ($$v) {_vm.popupShow=$$v},expression:"popupShow"}}),_c('nav-footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-setp-div active"},[_c('img',{staticClass:"box-setp-div-img",attrs:{"src":require("@/assets/image/hardware/step1.png"),"alt":"第一步"}}),_c('p',{staticClass:"box-setp-div-p"},[_vm._v("耳機測試")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-setp-arrow"},[_c('i',{staticClass:"box-setp-arrow-left"}),_c('img',{staticClass:"box-setp-arrow-img",attrs:{"src":require("@/assets/image/hardware/arrows.png"),"alt":""}}),_c('i',{staticClass:"box-setp-arrow-right"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-setp-div"},[_c('img',{staticClass:"box-setp-div-img",attrs:{"src":require("@/assets/image/hardware/steps2.png"),"alt":"第二步"}}),_c('p',{staticClass:"box-setp-div-p"},[_vm._v("麥克風測試")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-setp-arrow"},[_c('i',{staticClass:"box-setp-arrow-left"}),_c('img',{staticClass:"box-setp-arrow-img",attrs:{"src":require("@/assets/image/hardware/arrows.png"),"alt":""}}),_c('i',{staticClass:"box-setp-arrow-right"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-setp-div"},[_c('img',{staticClass:"box-setp-div-img",attrs:{"src":require("@/assets/image/hardware/steps3.png"),"alt":"第三步"}}),_c('p',{staticClass:"box-setp-div-p"},[_vm._v("鏡頭測試")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-setp-arrow"},[_c('i',{staticClass:"box-setp-arrow-left"}),_c('img',{staticClass:"box-setp-arrow-img",attrs:{"src":require("@/assets/image/hardware/arrows.png"),"alt":""}}),_c('i',{staticClass:"box-setp-arrow-right"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-setp-div"},[_c('img',{staticClass:"box-setp-div-img",attrs:{"src":require("@/assets/image/hardware/steps4.png"),"alt":"第四步"}}),_c('p',{staticClass:"box-setp-div-p"},[_vm._v("網速測試")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-setp-arrow"},[_c('i',{staticClass:"box-setp-arrow-left"}),_c('img',{staticClass:"box-setp-arrow-img",attrs:{"src":require("@/assets/image/hardware/arrows.png"),"alt":""}}),_c('i',{staticClass:"box-setp-arrow-right"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-setp-div"},[_c('img',{staticClass:"box-setp-div-img",attrs:{"src":require("@/assets/image/hardware/steps5.png"),"alt":"第五步"}}),_c('p',{staticClass:"box-setp-div-p"},[_vm._v("螢幕分享")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-content-left"},[_c('img',{attrs:{"src":require("@/assets/image/hardware/detection1.png"),"alt":"耳機測試"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"box-content-right-h1"},[_vm._v(" 耳機測試"),_c('span',[_vm._v("（聽力/口說測驗）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"box-content-right-h2"},[_vm._v(" 請按下播放鍵聆聽測試內容，"),_c('br'),_vm._v(" 並「將您的電腦調整至最適當的音量」。 ")])
}]

export { render, staticRenderFns }